(function (window, document) {
  const IFRAME_HOST = '{{WIDGET_SHOW_BASE_URL}}';
  const WIDGET_CONFIG = window.ReviewShowConfig || {};
  const RETRY_INTERVAL = 1000;
  const MAX_RETRY_COUNTS = 5;
  let RETRY_COUNTS = 0;

  document.addEventListener("DOMContentLoaded", function () {
    bindEvents();
  });

  async function bindEvents() {
    const widgetConfigSize = Object.keys(WIDGET_CONFIG).length;
    const reviewsContainer = document.getElementById(WIDGET_CONFIG.elementId);

    if (widgetConfigSize === 0) return;

    if (!WIDGET_CONFIG.hasOwnProperty("companyIds")) {
      throw new Error("companyId not found in ReviewCollectorConfig object ");
      return;
    }

    if (!WIDGET_CONFIG.hasOwnProperty("elementId")) {
      throw new Error("elementId not found in ReviewCollectorConfig object ");
      return;
    }

    if (WIDGET_CONFIG.hasOwnProperty("elementId") && !reviewsContainer) {
      if (RETRY_COUNTS === MAX_RETRY_COUNTS) return;
      Promise.reject();
      setTimeout(() => {
        bindEvents();
      }, RETRY_INTERVAL);
      RETRY_COUNTS++;
      throw new Error(
        `elementId with id "${WIDGET_CONFIG.elementId}" not found`
      );
    }

    loadIframeWidget(WIDGET_CONFIG);
    Promise.resolve();
  }

  function loadIframeWidget(reviewConfig) {
    const element = document.getElementById(reviewConfig.elementId);
    const iframe_url = IFRAME_HOST;

    console.log("Loading iframe widget...");

    if (element) {
      const iframe = document.createElement("iframe");
      iframe.setAttribute("src", iframe_url);
      iframe.setAttribute("name", JSON.stringify(reviewConfig));
      iframe.setAttribute("frameborder", "0");
      iframe.setAttribute("scrolling", "auto");
      iframe.setAttribute("width", "100%");
      iframe.setAttribute("height", "100%");
      iframe.setAttribute("style", "width: 100%;margin: auto");
      element.appendChild(iframe);
      window.addEventListener("message", (event) => {
        if (event.data.message === "size") {
          iframe.setAttribute("height", event.data.height);
        }
      });
    }
  }
})(window, document);
